import {Cargo} from './../cargos/cargo.model';
import {BaseModel} from 'app/main/shared/models/base.model';
import {BaseEnum} from '../../shared/models/base.enum ';
import {Pessoa} from '../pessoas/pessoa.model';
import {Pais} from '../paises/pais.model';

export class PessoaFisica extends BaseModel {
    constructor(
        public id?: number,
        public ativo?: boolean,
        public cpf?: string,
        public nome?: string,
        public nomeSocial?: string,
        public mae?: string,
        public pai?: string,
        public naturalidade?: string,
        public dataNascimento?: Date,
        public sexo?: string,
        public estadoCivil?: string,
        public pessoa?: Pessoa,
        public rg?: string,
        public rgOrgaoEmissor?: string,
        public rgUfEmitente?: string,
        public pais?: Pais,
        public cargo?: Cargo
    ) {
        super();
    }

    static sexos: BaseEnum[] = [
        {valor: 'M', descricao: 'Masculino'},
        {valor: 'F', descricao: 'Feminino'}
    ];

    static estadosCivis: BaseEnum[] = [
        {valor: 'SOLTEIRO', descricao: 'Solteiro'},
        {valor: 'CASADO', descricao: 'Casado'},
        {valor: 'VIUVO', descricao: 'Viúvo'},
        {valor: 'DIVORCIADO', descricao: 'Divorciado'},
        {valor: 'SEPARADO_JUDICIALMENTE', descricao: 'Separado Judicialmente'}
    ];

    static fromJson(json: any): PessoaFisica {
        if (json === undefined || json === null) {
            return null;
        }
        let pf: PessoaFisica = new PessoaFisica();
        pf = Object.assign({}, json);
        pf.cpf = pf.cpf ? pf.cpf.match(/\d+/g).join('') : null;
        if (!json.cpf) {
            pf.cpf = json.cpfCnpj;
        }
        return pf;
    }

}
