import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {FuseSharedModule} from '@fuse/shared.module';
import {CoreComponentsModule} from 'app/main/core/components/core-components.module';
import {CoreModule} from 'app/main/core/core.module';
import {SharedModule} from '../shared/shared.module';
import {DocumentosTemplateModule} from './documentos-template/documentos-template.module';
import {PessoaJuridicaModule} from './pessoa-juridica/pessoa-juridica.module';
import {PessoasFisicasModule} from './pessoas-fisicas/pessoas-fisicas.module';
import {ResponsaveisTecnicoModule} from './responsaveis-tecnico/responsaveis-tecnico.module';
import {ServidoresModule} from './servidores/servidores.module';
import {GestoesModule} from './gestoes/gestao.module';
import {OrganizacoesAdministrativasModule} from './gestoes/organizacoes-administrativas/organizacao-administrativa.module';
import {TaxonomiaModule} from './taxonomia/taxonomia.module';
import {GruposDocumentoModule} from './grupos-documento/grupos-documento.module';

const routes: Routes = [

    {
        path: 'documentos-template',
        loadChildren: './documentos-template/documentos-template.module#DocumentosTemplateModule'
    },
    {
        path: 'grupos-documento',
        loadChildren: './grupos-documento/grupos-documento.module#GruposDocumentoModule'
    },
    {
        path: 'pessoas-fisicas',
        loadChildren: './pessoas-fisicas/pessoas-fisicas.module#PessoasFisicasModule'
    },
    {
        path: 'pessoas-juridicas',
        loadChildren: './pessoa-juridica/pessoa-juridica.module#PessoaJuridicaModule'
    },
    {
        path: 'responsaveis-tecnicos',
        loadChildren: './responsaveis-tecnico/responsaveis-tecnico.module#ResponsaveisTecnicoModule'
    },
    {
        path: 'servidores',
        loadChildren: './servidores/servidores.module#ServidoresModule'
    },
    {
        path: 'gestoes',
        loadChildren: './gestoes/gestao.module#GestoesModule'
    },
    {
        path: 'organizacoes-administrativas',
        loadChildren: './gestoes/organizacoes-administrativas/organizacao-administrativa.module#OrganizacoesAdministrativasModule'
    },
    {
        path: 'taxonomias',
        loadChildren: './taxonomia/taxonomia.module#TaxonomiaModule'
    },
    {
        path: 'formularios-licenciamento-ambiental',
        loadChildren: './formularios-licenciamento-ambiental/formularios-licenciamento-ambiental.module#FormulariosLicenciamentoAmbientalModule'
    },
    {
        path: 'objetivo-licenciamento-ambiental',
        loadChildren: './objetivo-licenciamento-ambiental/objetivo-licenciamento-ambiental.module#ObjetivoLicenciamentoAmbientalModule'
    },
    {
        path: 'configuracao-licenciamento',
        loadChildren: './configuracao-licenciamento/configuracao-licenciamento.module#ConfiguracaoLicenciamentoModule'
    },
    {
        path: 'parametro-eia-rima',
        loadChildren: './parametro-eia-rima/parametro-eia-rima.module#ParametroEiaRimaModule'
    },
    {
        path: 'parametrizacao-objetivos',
        loadChildren: './parametrizacao-objetivos/parametrizacao-objetivos.module#ParametrizacaoObjetivosModule'
    },
    {
        path: 'parametro-licenciamento',
        loadChildren: './parametro-licenciamento/parametro-licenciamento.module#ParametroLicenciamentoModule'
    },
    {
        path: 'feicoes',
        loadChildren: './feicoes/feicoes.module#FeicoesModule'
    },
    {
        path: 'regras-geo-requerimento',
        loadChildren: './regras-geo-requerimento/regras-geo-requerimento.module#RegrasGeoRequerimentoModule'
    }
];

@NgModule({
    declarations: [],
    imports: [
        // Angular Core
        CommonModule,
        RouterModule.forChild(routes),

        // Fuse
        FuseSharedModule,

        CoreModule.forRoot(),
        CoreComponentsModule,
        DocumentosTemplateModule,
        GruposDocumentoModule,
        PessoasFisicasModule,
        PessoaJuridicaModule,
        ResponsaveisTecnicoModule,
        ServidoresModule,
        GestoesModule,
        OrganizacoesAdministrativasModule,
        TaxonomiaModule,
        SharedModule,
    ]
})
export class PagesModule {
}
