import {Component, Input, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material';
import {ResponsavelTecnico} from 'app/main/pages/responsaveis-tecnico/responsavel-tecnico.model';
import {DocumentoResponsavelTecnicoDialogComponent} from '../documento-responsavel-tecnico-dialog/documento-responsavel-tecnico-dialog.component';
import {DocumentoResponsavelTecnico} from './../../../pages/documentos-responsaveis-tecnicos/documento-responsavel-tecnico.model';
import {DocumentoResponsavelTecnicoService} from './../../../pages/documentos-responsaveis-tecnicos/documento-responsavel-tecnico.service';
import {Documento} from './../../../pages/documentos/documento.model';
import {DocumentoTemplate} from '../../../pages/documentos-template/documento-template.model';
import {SnackBarService} from '../../services/snack-bar/snack-bar.service';

@Component({
    selector: 'app-documento-responsavel-tecnico',
    templateUrl: './documento-responsavel-tecnico.component.html'
})
export class DocumentoResponsavelTecnicoComponent implements OnInit {
    @Input() responsavelTecnico: ResponsavelTecnico;
    @Input() isSomenteVisualizacao: boolean;
    documentos: DocumentoResponsavelTecnico[];

    constructor(
        protected documentoResponsavelTecnicoService: DocumentoResponsavelTecnicoService,
        protected dialog: MatDialog,
        protected snackBarService: SnackBarService
    ) {
    }

    ngOnInit(): void {
        this.listarDocumentos();
    }

    public listarDocumentos(): void {
        this.documentoResponsavelTecnicoService
            .getByResponsavelId(this.responsavelTecnico)
            .subscribe(documentos => (this.documentos = documentos));
    }

    public documentoDialog(documento?: Documento): void {
        const dialogRef = this.dialog.open(
            DocumentoResponsavelTecnicoDialogComponent,
            {
                width: '600px',
                data:
                    documento === undefined
                        ? new Documento(
                        null,
                        null,
                        null,
                        null,
                        this.responsavelTecnico
                        )
                        : documento
            }
        );

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.listarDocumentos();
            }
        });
    }

    updateDocumento(multipartFile, documento?: Documento): void {
        (documento.id === null
                ? this.documentoResponsavelTecnicoService.createMultipart(
                    documento,
                    multipartFile
                )
                : this.documentoResponsavelTecnicoService.updateMultipart(
                    documento,
                    multipartFile
                )
        ).subscribe(
            () => {
                this.listarDocumentos();
            },
            (e) => {
                this.snackBarService.showError(
                    'Não foi possível salvar o registro.', e);
            }
        );
    }

    public visualizarDocumento(documento: Documento): void {
        window.open(
            this.documentoResponsavelTecnicoService.urlResource +
            '/' +
            documento.id +
            '/visualizar'
        );
    }

    public downloadDocumento(documento: Documento): void {
        window.open(
            this.documentoResponsavelTecnicoService.urlResource +
            '/' +
            documento.id +
            '/download'
        );
    }

    public formatarLabelTamanhoDoc(tamanho, opcaoTamanho): string {
        if (opcaoTamanho === 'DOC') {
            DocumentoTemplate.tamanhosDocumento.forEach(t => {
                if (t.valor === tamanho) {
                    tamanho = t.descricao;
                }
            });
            return tamanho;
        }
        DocumentoTemplate.tamanhosPagina.forEach(t => {
            if (t.valor === tamanho) {
                tamanho = t.descricao;
            }
        });
        return tamanho;
    }
}
