import {Injectable, Injector} from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AtividadeArrecadacao} from '../../models/arrecadacao-models/atividade-arrecadacao.model';
import {BaseArrecadacaoService} from '../base-arrecadacao.service';
import {MatPaginator} from "@angular/material/paginator";
import {Page} from "../../models/page.model";
import {ParametroLicenciamento} from "../../../pages/parametro-licenciamento/parametro-licenciamento.model";
import {HttpParams} from "@angular/common/http";

@Injectable({
    providedIn: 'root'
})
export class AtividadeArrecadacaoService extends BaseArrecadacaoService<AtividadeArrecadacao> {

    constructor(protected injector: Injector) {
        super('/api/atividade', injector, AtividadeArrecadacao.prototype, AtividadeArrecadacao.fromJson);
    }

    getAllAtividades(): Observable<AtividadeArrecadacao[]> {
        const url = `${this.urlResourceArrecadacao}/buscar-atividades`;
        return this.http
            .get(url)
            .pipe(
                map(response => this.jsonToResources(response)),
                catchError(this.handleError.bind(this))
            );
    }

    public getByDescricao(descricao?: string): Observable<{} | Array<AtividadeArrecadacao>> {
        const url = `${this.urlResourceArrecadacao}/paginada/descricao/${descricao}`;
        return this.http
            .get(url)
            .pipe(
                map(response => this.jsonToResources(response)),
                catchError(this.handleError.bind(this))
            );
    }

}
