import { BaseModel } from 'app/main/shared/models/base.model';
import { BaseEnum } from 'app/main/shared/models/base.enum ';
import {PessoaFisica} from "../pessoas-fisicas/pessoa-fisica.model";
import {Pessoa} from "../pessoas/pessoa.model";

export class ResponsavelTecnico extends BaseModel {

    constructor(
        public id?: number,
        public cadastroTecFederal?: string,
        public registroConselho?: string,
        public tipoPessoa?: string,
        public pessoaId?: number,
        public conselhoId?: number,
        public conselho?: string,
        public nomeRazaoSocial?: any,
        public cpfCnpj?: string,
        public dataInicio?: Date,
        public dataFim?: Date,
        public ativo?: boolean,
        public pessoa?: PessoaFisica,
    ) {
        super();
    }

    static tiposPessoa: BaseEnum[] = [
        { valor: 'PF', descricao: 'Pessoa física' },
        { valor: 'PJ', descricao: 'Pessoa jurídica'}
    ];

    static fromJson(json: any): ResponsavelTecnico {
        if (json === undefined || json === null) {
            return null;
        }
        return new ResponsavelTecnico(
            json.id,
            json.cadastroTecFederal,
            json.registroConselho,
            json.tipoPessoa,
            json.pessoaId ? json.pessoaId : json.pessoa ? json.pessoa.id : null,
            json.conselhoId ? json.conselhoId : json.conselho ? json.conselho.id : null,
            json.conselho,
            json.nomeRazaoSocial,
            json.cpfCnpj,
            json.dataInicio,
            json.dataFim,
            json.ativo,
            PessoaFisica.fromJson(json.pessoa)
        );
    }
    
}
