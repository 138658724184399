import {Component, Input, OnInit} from '@angular/core';
import {AtividadeArrecadacao} from "../../models/arrecadacao-models/atividade-arrecadacao.model";
import {AtividadeArrecadacaoService} from "../../services/arrecadacao-service/atividade-arrecadacao.service";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: 'app-select-atividades-arrecadacao',
    templateUrl: './select-atividades-arrecadacao.component.html',
    styleUrls: ['./select-atividades-arrecadacao.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: SelectAtividadesArrecadacaoComponent,
        multi: true
    }]
})
export class SelectAtividadesArrecadacaoComponent implements OnInit, ControlValueAccessor {
    private onChange = (_) => {
    };
    private onTouched = () => {
    };

    atividadesSelecionadas?: Array<{ atividade: AtividadeArrecadacao, vinculo: any }>;

    isSomenteVisualizacao: boolean;

    atividadesFiltradas: any = [];

    displayedColumnsAtividade: string[] = ['atividade', 'acao'];
    atividade: AtividadeArrecadacao;

    constructor(private atividadeArrecadacaoService: AtividadeArrecadacaoService) {
    }

    @Input() set showSubgrupos(showSubgrupos: any) {
        if (showSubgrupos || showSubgrupos === '') {
            this.displayedColumnsAtividade = ['subgrupo', 'atividade', 'acao'];
        } else {
            this.displayedColumnsAtividade = ['atividade', 'acao'];
        }
    }

    ngOnInit() {
    }

    excluirAtividade(index: number) {
        if (index > -1) {
            this.atividadesSelecionadas = [
                ...this.atividadesSelecionadas.filter((a, idx) => idx !== index)
            ];

            if (!this.atividadesSelecionadas.length) {
                this.atividadesSelecionadas = undefined;
            }

            this.onTouched();
            this.onChange(this.atividadesSelecionadas);
        }
    }

    addAtividade() {
        if (!!this.atividade && typeof this.atividade !== 'string') {
            const vinculo = {atividade: this.atividade.id} as any;

            if (this.atividade && (!this.atividadesSelecionadas || !this.atividadesSelecionadas.some(atv => atv.vinculo.atividade === vinculo.atividade))) {
                this.atividadesSelecionadas = [
                    ...(this.atividadesSelecionadas || []),
                    {
                        atividade: this.atividade,
                        vinculo: vinculo
                    }
                ];
                this.onTouched();
                this.onChange(this.atividadesSelecionadas);
                this.atividade = undefined;
            }
        }
    }

    async pesquisaAtividadePorDesc(event) {
        const desc = event.target.value;
        if (desc.trim().length === 0) {
            return;
        } else if (desc.trim().length > 2) {
            this.atividadesFiltradas = await this.atividadeArrecadacaoService
                .getByDescricao(desc)
                .toPromise();
        }
    }

    getDescricao(atividade: AtividadeArrecadacao) {
        if (atividade != null) {
            return atividade.descricao
        } else {
            return ''
        }
    }

    writeValue(obj: any): void {
        if (obj && obj.length) {
            this.atividadesSelecionadas = obj;
        } else {
            this.atividadesSelecionadas = undefined;
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    setDisabledState?(isDisabled: boolean): void {
        this.isSomenteVisualizacao = isDisabled;
    }


}
