import { Injectable, Injector } from '@angular/core';
import { GrupoCamunda } from 'app/main/shared/models/grupo-camunda.model';
import { BaseService } from 'app/main/shared/services/base.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GrupoCamundaService extends BaseService<GrupoCamunda> {

    // urlPortalApi: string = environment.URL_PORTAL_API;
    urlResource: string = environment.URL_SCORP_API;

    constructor(protected injector: Injector) {
        super('/', injector, GrupoCamunda.prototype, GrupoCamunda.fromJson);
    }

    getAllGrupos(): Observable<GrupoCamunda[]> {
        const url = this.urlResource + `/api/grupos-camunda`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }
}
