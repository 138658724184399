import {BaseModel} from 'app/main/shared/models/base.model';
import * as moment from 'moment';

export class ServidorVinculo extends BaseModel {
    constructor(
        public id?: number,
        public servidorId?: number,
        public cargoId?: number,
        public organizacaoId?: number,
        public cargoDescricao?: string,
        public organizacaoNome?: string,
        public dataInicio?: string,
        public dataFim?: string,
        public ativo?: boolean
    ) {
        super();
    }

    static fromJson(json: any): ServidorVinculo {
        json = json || {};

        if (typeof json.dataInicio === 'string') {
            json.dataInicio = moment.utc(json.dataInicio).local().format('DD/MM/YYYY');
        }

        if (typeof json.dataFim === 'string') {
            json.dataFim = moment.utc(json.dataFim).local().format('DD/MM/YYYY');
        }

        return new ServidorVinculo(
            json.id,
            json.servidorId,
            json.cargoId,
            json.organizacaoId,
            json.cargoDescricao,
            json.organizacaoNome,
            json.dataInicio,
            json.dataFim,
            json.ativo
        );
    }

    static fromIterable(json: any): ServidorVinculo[] {
        const vinculos: ServidorVinculo[] = [];

        if (json && typeof json[Symbol.iterator] === 'function') {
            for (const value of json) {
                if (value) {
                    vinculos.push(ServidorVinculo.fromJson(value));
                }
            }
        }

        return vinculos;
    }
}

export class Servidor extends BaseModel {
    constructor(
        public id?: number,
        public matricula?: string,
        public cpf?: string,
        public rg?: string,
        public nome?: string,
        public nomeSocial?: string,
        public dataNascimento?: string,
        public cargoId?: number,
        public cargo?: string,
        public pessoaFisicaId?: number,
        public profissoes?: string,
        public ativo?: boolean,
        public emails?: string[],
        public telefones?: string[]) {
        super();
    }

    static fromJson(json: any): Servidor {
        json = json || {};

        if (typeof json.dataNascimento === 'string') {
            json.dataNascimento = moment.utc(json.dataNascimento).local().format('DD/MM/YYYY');
        }

        const id = typeof json.pessoa === 'object' ? 0 : json.id || 0;
        const cargoId = (json.cargo && typeof json.cargo === 'object') ? json.cargo.id : json.cargoId || 0;
        const cargo = (json.cargo && typeof json.cargo === 'object') ? json.cargo.descricao : json.cargo || '';
        const pessoaFisicaId = typeof json.pessoa === 'object' ? json.id : json.pessoaFisicaId || 0;

        return new Servidor(
            id,
            json.matricula,
            json.cpf,
            json.rg,
            json.nome,
            json.nomeSocial,
            json.dataNascimento,
            cargoId,
            cargo,
            pessoaFisicaId,
            json.profissoes,
            json.ativo,
            json.emails,
            json.telefones
        );
    }
}
