import {Injectable, Injector} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {BaseService} from 'app/main/shared/services/base.service';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';
import {DetailResolver} from 'app/main/shared/resolvers/detail-resolver';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';
import {Servidor, ServidorVinculo} from './servidor.model';

@Injectable()
export class ServidorListResolver extends ListResolver<Servidor> {
    constructor(service: ServidorService) {
        super(service);
    }
}

@Injectable()
export class ServidorResolver extends DetailResolver<Servidor> {
    constructor(service: ServidorService, routingStateService: RoutingStateService) {
        super(service, routingStateService);
    }
}

@Injectable({ providedIn: 'root' })
export class ServidorService extends BaseService<Servidor> {
    constructor(protected injector: Injector) {
        super(
            '/api/servidores',
            injector,
            Servidor.prototype,
            Servidor.fromJson
        );
    }

    createVinculo(servidor: Servidor, vinculo: ServidorVinculo): Observable<ServidorVinculo> {
        const url = `${this.urlResource}/${servidor.id}/vinculos`;

        return this.http
            .post(url, vinculo)
            .pipe(
                map(ServidorVinculo.fromJson.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    deleteVinculo(vinculo: ServidorVinculo): Observable<any> {
        const url = `${this.urlResource}/${vinculo.servidorId}/vinculos/${vinculo.id}`;

        return this.http
            .delete(url)
            .pipe(
                map(() => null),
                catchError(this.handleError.bind(this))
            );
    }

    getVinculos(servidor: Servidor): Observable<ServidorVinculo[]> {
        const url = `${this.urlResource}/${servidor.id}/vinculos`;

        return this.http
            .get(url)
            .pipe(
                map(ServidorVinculo.fromIterable.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    getByCpf(cpf: string): Observable<Servidor> {
        const value = cpf.match(/\d+/g).join('');
        const url = `${this.urlResource}/cpf/${value}`;

        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResourceFn.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

    protected handleError(error: any): Observable<any> {
        if (error) {
            if (error.status === 404) {
                return of(null);
            }

            if (error.error && error.error.message) {
                error.toString = () => error.error.message;
            }
        }

        return super.handleError(error);
    }
}
