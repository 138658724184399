import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { FuseSharedModule } from '@fuse/shared.module';
import { Error401Component } from 'app/main/errors/401/error-401.component';
import { Error404Component } from 'app/main/errors/404/error-404.component';
import { Error500Component } from 'app/main/errors/500/error-500.component';
import { PageErrorComponent } from 'app/main/errors/page-error.component';

const routes = [
    {
        path: 'error',
        component: PageErrorComponent,
    },
    {
        path: 'error/error-401',
        component: Error401Component,
    },
    {
        path: 'error/error-404',
        component: Error404Component,
    },
    {
        path: 'error/error-500',
        component: Error500Component,
    },
];

@NgModule({
    declarations: [
        PageErrorComponent,
        Error401Component,
        Error404Component,
        Error500Component,
    ],
    imports: [RouterModule.forChild(routes), MatIconModule, FuseSharedModule],
    entryComponents: [Error401Component, Error404Component, Error500Component],
    exports: [
        PageErrorComponent,
        Error401Component,
        Error404Component,
        Error500Component,
    ],
})
export class PageErrorsModule {}
