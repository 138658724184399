import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CoreComponentsModule } from 'app/main/core/components/core-components.module';
import { SharedModule } from 'app/main/shared/shared.module';
import { CoreModule } from './../../core/core.module';
import { ResponsavelTecnicoDetailComponent } from './responsavel-tecnico-detail/responsavel-tecnico-detail.component';
import { ResponsavelTecnicoListComponent } from './responsavel-tecnico-list/responsavel-tecnico-list.component';
import { ResponsavelTecnicoListResolver, ResponsavelTecnicoResolver } from './responsavel-tecnico.service';

export const routes: Routes = [
  {
      path: 'new',
      component: ResponsavelTecnicoDetailComponent
  },
  {
      path: 'responsaveis-tecnicos',
      component: ResponsavelTecnicoListComponent,
      resolve: {
          data: ResponsavelTecnicoListResolver
      }
  },
  {
      path: ':id/edit',
      component: ResponsavelTecnicoDetailComponent,
      resolve: {
          data: ResponsavelTecnicoResolver
      }
  },
  {
      path: ':id/detail',
      component: ResponsavelTecnicoDetailComponent,
      resolve: {
          data: ResponsavelTecnicoResolver
      }
  }
];

@NgModule({
    declarations: [
        ResponsavelTecnicoListComponent, 
        ResponsavelTecnicoDetailComponent
      ],
    imports: [
        CoreModule.forRoot(), 
        CoreComponentsModule, 
        SharedModule,
        RouterModule.forChild(routes)],
    entryComponents: [
        ResponsavelTecnicoDetailComponent, 
        ResponsavelTecnicoListComponent],
    providers: [
       ResponsavelTecnicoResolver, 
       ResponsavelTecnicoListResolver
    ]
  
})
export class ResponsaveisTecnicoModule { }
