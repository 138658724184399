import {Pais} from './../../paises/pais.model';
import {PaisService} from './../../paises/pais.service';
import {ENUM_UF_VALUES, EnumUf} from 'app/main/shared/models/uf.enum';
import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {BaseDetailComponent} from 'app/main/shared/components/base-detail/base-detail.component';
import {PessoaFisica} from '../pessoa-fisica.model';
import {Validators} from '@angular/forms';
import {PessoaFisicaService} from '../pessoa-fisica.service';
import {fuseAnimations} from '@fuse/animations';
import {CoreMessageService, ISEVERITY} from 'app/main/core/services/core-message.service';
import {Contato} from '../../contato/contatos.model';
import {Pessoa} from '../../pessoas/pessoa.model';
import * as moment from 'moment';

@Component({
    selector: 'app-pessoa-fisica-detail',
    templateUrl: './pessoa-fisica-detail.component.html',
    changeDetection: ChangeDetectionStrategy.Default,
    animations: fuseAnimations
})
export class PessoaFisicaDetailComponent
    extends BaseDetailComponent<PessoaFisica> implements OnInit {

    maxDate = moment().subtract(16, 'years');
    minDate = moment().subtract(120, 'years');
    ufValues = ENUM_UF_VALUES;
    paises: Pais[];
    compareWith = Pais.compareWith;

    constructor(
        protected pessoaFisicaService: PessoaFisicaService,
        protected paisService: PaisService,
        protected injector: Injector,
        protected messageService: CoreMessageService
    ) {
        super(
            injector,
            new PessoaFisica(),
            pessoaFisicaService,
            PessoaFisica.fromJson
        );
    }

    ngOnInit(): any {
        super.ngOnInit();
        this.loadPaises();
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            cpf: [null, [Validators.required]],
            nome: [null, [Validators.required, Validators.maxLength(200)]],
            mae: [null, [Validators.required, Validators.maxLength(200)]],
            pai: [null, Validators.maxLength(200)],
            naturalidade: [null, [Validators.required, Validators.maxLength(200)]],
            nomeSocial: [null, Validators.maxLength(200)],
            dataNascimento: [
                {value: null, disabled: this.isSomenteVisualizacao}
            ],
            sexo: [{value: null, disabled: this.isSomenteVisualizacao}],
            estadoCivil: [
                {value: null, disabled: this.isSomenteVisualizacao}
            ],
            pessoa: this.formBuilder.group({
                id: [null],
                ativo: [{value: false, disabled: this.isSomenteVisualizacao}]
            }),
            rg: [null, [Validators.required, Validators.maxLength(20)]],
            rgOrgaoEmissor: [null, [Validators.required, Validators.maxLength(20)]],
            rgUfEmitente: [{value: null, disabled: this.isSomenteVisualizacao}],
            pais: [
                {value: null, disabled: this.isSomenteVisualizacao},
                [Validators.required]
            ]
        });
    }

    get dataNascimento(): any {
        return this.form.get('dataNascimento');
    }

    protected actionsForError(response: any): any {
        if (response.error && response.error.errors) {
            response.error.errors.forEach(e => {
                if (e.split(':').length > 1) {
                    this.messageService.add({
                        severity: ISEVERITY.WARN,
                        message: 'Atenção',
                        description: e.split(':')[1]
                    });
                } else {
                    this.messageService.add({
                        message: 'Erro',
                        description: e
                    });
                }
            });
        }
    }

    protected creationPageTitle(): string {
        return 'Cadastro de nova pessoa fisica';
    }

    protected editionPageTitle(): string {
        const resourceName = this.resource.nome || '';
        return 'Editando a pessoa: ' + resourceName;
    }

    protected visualizationPageTitle(): string {
        const resourceName = this.resource.nome || '';
        return 'Visualizando a pessoa: ' + resourceName;
    }

    get sexos(): any {
        return PessoaFisica.sexos;
    }

    get estadosCivis(): any {
        return PessoaFisica.estadosCivis;
    }

    get contato(): Contato {
        return this.resource.pessoa.contato;
    }

    get pessoaFisica(): PessoaFisica {
        return this.resource;
    }

    get pessoa(): Pessoa {
        return this.resource.pessoa;
    }

    getUfTexto(tipo: string): string {
        return EnumUf[tipo];
    }

    private loadPaises(): any {
        this.paisService
            .getAll()
            .subscribe(paises => {
                this.paises = paises;
            });
    }

}
