export const environment = {
    production: true,
    name: 'production',
    hmr: false,
    URL_GATEWAY: 'https://licenciamento-configuracao-api.sema.mt.gov.br',
    URL_BPMS: 'https://bpms.sema.mt.gov.br',
    URL_WSO2_AUTH: '',
    URL_WSO2_USER_INFO: '',
    URL_PORTAL: 'https://portal.sema.mt.gov.br',
    URL_LICENCIAMENTO: 'https://licenciamento.sema.mt.gov.br',
    URL_LICENCIAMENTO_API: 'https://licenciamento-api.sema.mt.gov.br',
    URL_ARRECADACAO_API: 'https://arrecadacao-api.sema.mt.gov.br',
    URL_SIMCAR_API: 'https://simcar-api.sema.mt.gov.br',
    URL_PORTAL_API: 'https://portal-api.sema.mt.gov.br',
    URL_SCORP_API: 'https://scorp-api.sema.mt.gov.br',
    URL_GEOPORTAL_BASE_REFERENCIA: 'https://basegeo-api.sema.mt.gov.br',
    URL_HIDRICO: 'https://hidrico-api.sema.mt.gov.br',
    URL_DOMINIOS_API: 'https://dominios-api.sema.mt.gov.br',
    URL_GEOADMIN: 'https://geoadmin-api.sema.mt.gov.br',
    OAUTH_CLIENT_KEY: 'M4xK_8Jjhf0XVdB7cpBiP0JoZ00a',
    OAUTH_CLIENT_SECRET: 'aqEtaGeG0Yc5gh4SW0g2_OeEQYYa',
    UF_PADRAO: 'MT',
    OAUTH_SCOPES: 'email openid profile address photourl picture',
    IDENTITY_COOKIE_NAME: 'br.gov.mt.sema.identity'
};