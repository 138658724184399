import {Component, Injector, OnInit} from '@angular/core';
import {PessoaJuridicaService} from 'app/main/pages/pessoa-juridica/pessoa-juridica.service';
import {BaseDetailComponent} from 'app/main/shared/components/base-detail/base-detail.component';
import {Validators} from '@angular/forms';
import {PessoaJuridica} from 'app/main/pages/pessoa-juridica/pessoa-juridica.model';
import {Contato} from '../../contato/contatos.model';
import {Pessoa} from '../../pessoas/pessoa.model';
import { PessoaService } from '../../pessoas/pessoa.service';

@Component({
    selector: 'app-pessoa-juridica-detail',
    templateUrl: './pessoa-juridica-detail.component.html',
})
export class PessoaJuridicaDetailComponent extends BaseDetailComponent<PessoaJuridica> implements OnInit {

    maxDate = new Date();
    public pessoaJuridica: any;
    dadosPrincipaisValidacao: boolean;
    enderecoValidacao: boolean;
    telefoneValidacao: boolean;
    emailValidacao: boolean;
    documentoValidacao: boolean;
    atividadeValidacao: boolean;

    validacaoFormulario: boolean;

    changeAtividade: any;

    constructor(
        protected pessoaJuridicaService: PessoaJuridicaService,
        protected pessoaService: PessoaService,
        protected injector: Injector
    ) {
        super(
            injector,
            new PessoaJuridica(),
            pessoaJuridicaService,
            PessoaJuridica.fromJson
        );

    }

    ngOnInit(): void {
        super.ngOnInit();
        if (this.form.valid) {
            this.validacaoCadastro('dadosPrincipais', 'VALID');
        }
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            ativo: [{value: null, disabled: this.isSomenteVisualizacao}],
            cnpj: [{value: null, disabled: this.isSomenteVisualizacao}, [Validators.required]],
            nomeFantasia: [null, [Validators.required, Validators.maxLength(200)]],
            razaoSocial: [null, [Validators.maxLength(200)]],
            inscricaoMunicipal: [null, [Validators.maxLength(30)]],
            inscricaoEstadual: [null, [Validators.maxLength(30)]],
            naturezaJuridica: [null, [Validators.maxLength(80)]],
            porteEmpresa: [null, [Validators.maxLength(10)]],
            dataConstituicao: [new Date()],
            dataCriacao: [new Date()],
            pessoa: this.formBuilder.group({
                ativo: [
                    {value: null, disabled: this.isSomenteVisualizacao}
                ],
            })
        });
    }

    protected creationPageTitle(): string {
        return 'Cadastro de nova Pessoa Jurídica';
    }

    protected editionPageTitle(): string {
        const resourceName =
            this.resource.razaoSocial ||
            this.resource.nomeFantasia ||
            'Nova empresa';
        return 'Editando: ' + resourceName;
    }

    protected visualizationPageTitle(): string {
        const resourceName =
            this.resource.razaoSocial ||
            this.resource.nomeFantasia ||
            'Nova empresa';
        return 'Visualizando: ' + resourceName;
    }

    get contato(): Contato {
        return this.resource.pessoa.contato;
    }

    get pessoa(): Pessoa {
        return this.resource.pessoa;
    }

    public validacaoCadastro(aba: string, status): void {
        switch (aba) {
            case 'dadosPrincipais':
                this.dadosPrincipaisValidacao = status === 'VALID';
                break;
            case 'endereco':
                this.enderecoValidacao = status === 'VALID';
                break;
            case 'telefone':
                this.telefoneValidacao = status === 'VALID';
                break;
            case 'email':
                this.emailValidacao = status === 'VALID';
                break;
            case 'atividade':
                this.atividadeValidacao = status === 'VALID';
                this.changeAtividade = status;
                if(!this.atividadeValidacao) {
                    this.isCadastroIncompleto();
                }
                break;
            case 'documento':
                this.documentoValidacao = status === 'VALID';
                break;

        }
        this.validacaoFormulario = this.documentoValidacao && this.enderecoValidacao && this.telefoneValidacao && this.emailValidacao && this.atividadeValidacao;

    }

    isCadastroIncompleto(): void {
        this.pessoaService.tornarCadastroIncompleto(this.pessoa).subscribe(() => {
            //this.snackBarService.showSuccess('Cadastro finalizado com sucesso !');            
        }, (erro) => {
            this.snackBarService.showError('Erro ao realizar cadastro !');
        });
    }

    salvar(): void {
        this.submittingForm = true;
        this.form.markAllAsTouched();

        if (this.form.valid) {
            if (this.currentAction === 'new') {
                this.createResource();
            } else if (this.currentAction === 'edit') {
                this.updateResource();
            }
            this.validacaoCadastro('dp', true);
        }

    }

}
