import {HttpParams} from '@angular/common/http';
import {Component, Injector, OnInit} from '@angular/core';
import {fuseAnimations} from '@fuse/animations';
import {BaseListComponent} from 'app/main/shared/components/base-list/base-list.component';
import {ResponsavelTecnicoService} from '../responsavel-tecnico.service';
import {ResponsavelTecnico} from '../responsavel-tecnico.model';
import {AuthenticationService} from '../../../core/services/authentication.service';

@Component({
    selector: 'app-responsavel-tecnico-list',
    templateUrl: './responsavel-tecnico-list.component.html',
    animations: fuseAnimations
})
export class ResponsavelTecnicoListComponent extends BaseListComponent<ResponsavelTecnico> implements OnInit {
    descricao = '';
    usuarioLogado;

    constructor(
        injector: Injector,
        service: ResponsavelTecnicoService,
        private authenticationService: AuthenticationService
    ) {
        super(injector, ['id', 'cpfCnpj', 'nomeRazaoSocial', 'tipoPessoa', 'cadastroTecFederal', 'conselho', 'registroConselho', 'ativo'], service);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.authenticationService.currentUser.subscribe(user => this.usuarioLogado = user);
    }

    fillFilters(httpParams: HttpParams ):
        any {
        if (httpParams == null) {
            httpParams = new HttpParams();
        }

        httpParams = httpParams.append('descricao', this.descricao);
        return httpParams;
    }
}
