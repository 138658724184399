import {Component, Input, OnInit} from '@angular/core';
import {ObjetivoLicenciamentoAmbiental} from "../../../pages/objetivo-licenciamento-ambiental/objetivo-licenciamento-ambiental.model";
import {MatTableDataSource} from "@angular/material/table";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
    selector: 'app-select-objetivos-licenciamento-ambiental',
    templateUrl: './select-objetivos-licenciamento-ambiental.component.html',
    styleUrls: ['./select-objetivos-licenciamento-ambiental.component.scss'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: SelectObjetivosLicenciamentoAmbientalComponent,
        multi: true
    }]
})
export class SelectObjetivosLicenciamentoAmbientalComponent implements OnInit, ControlValueAccessor {

    private onChange = (_) =>  {};
    private onTouched = () => {};

    objetivosSelecionados?: Array<{ objetivo: ObjetivoLicenciamentoAmbiental, vinculo: any }>;

    @Input() objetivos: Array<ObjetivoLicenciamentoAmbiental> = [];

    isSomenteVisualizacao;

    displayedColumnsObjetivo: string[] = ['objetivo', 'acao'];

    objetivo: ObjetivoLicenciamentoAmbiental;

    constructor() {
    }

    ngOnInit() {
    }

    excluirObjetivo(index: number) {
        if (index > -1) {
            this.objetivosSelecionados = [
                ...this.objetivosSelecionados.filter((o, idx) => idx !== index)
            ];

            if (!this.objetivosSelecionados.length) {
                this.objetivosSelecionados = undefined;
            }

            this.onTouched();
            this.onChange(this.objetivosSelecionados);
        }
    }

    addObjetivo() {
        let vinculo = {} as any;

        vinculo.objetivoLicenciamento = this.objetivo.id;

        if (this.objetivo &&
            (!this.objetivosSelecionados || !this.objetivosSelecionados.some(obj => obj.vinculo.objetivoLicenciamento === vinculo.objetivoLicenciamento))) {
            this.objetivosSelecionados = [
                ...(this.objetivosSelecionados || []),
                {
                    objetivo: this.objetivo,
                    vinculo: vinculo
                }
            ];
            this.onTouched();
            this.onChange(this.objetivosSelecionados)
            this.objetivo = undefined;
        }
    }

    setDisabledState(isDisabled: boolean) {
        this.isSomenteVisualizacao = isDisabled;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    writeValue(obj: any): void {
        if (obj && obj.length) {
            this.objetivosSelecionados = obj;
        } else {
            this.objetivosSelecionados = undefined;
        }
    }
}
