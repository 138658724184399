import {DocumentoComponent} from './components/documento/documento.component';
import {DocumentoDialogComponent} from './components/documento-dialog/documento-dialog.component';
import {EnderecoComponent} from './components/endereco/endereco.component';
import {TelefoneComponent} from './components/telefone/telefone.component';
import {EmailComponent} from './components/email/email.component';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {AddNewButtonComponent} from './components/add-new-button/add-new-button.component';
import {ConfirmarExclusaoDialogComponent} from './components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule
} from '@angular/material';
import {MaskPipe, NgxMaskModule} from 'ngx-mask';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DocumentoResponsavelTecnicoComponent} from './components/documento-responsavel-tecnico/documento-responsavel-tecnico.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatSpinnerOverlayComponent} from './components/mat-spinner-overlay/mat-spinner-overlay.component';
import {DocumentoResponsavelTecnicoDialogComponent} from './components/documento-responsavel-tecnico-dialog/documento-responsavel-tecnico-dialog.component';
import {AtividadeComponent} from './components/atividades/atividade.component';
import {NgxMatSelectSearchModule} from 'ngx-mat-select-search';
import {MatSelectInfiniteScrollModule} from 'ng-mat-select-infinite-scroll';
import {ResponsaveisComponent} from './components/responsaveis/responsaveis.component';
import {CustomSnackBarComponent} from './services/snack-bar/custom-snack-bar.component';
import {PessoaFisicaProfissaoComponent} from './components/pessoas-fisica-profissoes/pessoas-fisica-profissoes.component';
import {BaseEnumDescricaoPipe} from './pipes/base-enum-descricao.pipe';
import {CpfCnpjMaskPipe} from './pipes/cpf-cnpj-mask.pipe';
import {DialogEditarVinculoPfPjComponent} from './components/responsaveis/dialog-editar-vinculo-pf-pj/dialog-editar-vinculo-pf-pj.component';
import {DialogSelecionarPfComponent} from './components/dialog-selecionar-pf/dialog-selecionar-pf.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {DialogSelecionarEmpresaComponent} from './components/dialog-selecionar-empresa/dialog-selecionar-empresa.component';
import {SelectSearchComponent} from './components/select-search/select-search.component';
import { SelectAtividadesArrecadacaoComponent } from './components/select-atividades-arrecadacao/select-atividades-arrecadacao.component';
import { SelectObjetivosLicenciamentoAmbientalComponent } from './components/select-objetivos-licenciamento-ambiental/select-objetivos-licenciamento-ambiental.component';
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {NumbersOnlyDirective} from "./directives/numbers-only.directive";
import { PesquisarPipe } from './pipes/pesquisar.pipe';

@NgModule({
    providers: [
        {provide: MaskPipe, useClass: MaskPipe}
    ],
    declarations: [
        ConfirmarExclusaoDialogComponent,
        AddNewButtonComponent,
        SelectSearchComponent,
        MatSpinnerOverlayComponent,
        EmailComponent,
        TelefoneComponent,
        EnderecoComponent,
        DocumentoDialogComponent,
        DocumentoResponsavelTecnicoDialogComponent,
        DocumentoComponent,
        DocumentoResponsavelTecnicoComponent,
        AtividadeComponent,
        ResponsaveisComponent,
        PessoaFisicaProfissaoComponent,
        CustomSnackBarComponent,
        BaseEnumDescricaoPipe,
        CpfCnpjMaskPipe,
        DialogEditarVinculoPfPjComponent,
        DialogSelecionarPfComponent,
        DialogSelecionarEmpresaComponent,
        SelectAtividadesArrecadacaoComponent,
        SelectObjetivosLicenciamentoAmbientalComponent,
        NumbersOnlyDirective,
        PesquisarPipe
    ],
    imports: [
        CommonModule,
        RouterModule,
        NgxMaskModule.forRoot({
            validation: true
        }),

        MatDialogModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        MatButtonModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatDialogModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatRadioModule,
        FlexLayoutModule,
        NgxMatSelectSearchModule,
        MatSelectInfiniteScrollModule,
        FormsModule,
        MatTooltipModule,
        MatAutocompleteModule
    ],
    exports: [
        RouterModule,
        MatMenuModule,
        MatIconModule,
        MatCardModule,
        NgxMaskModule,
        MatButtonModule,
        MatChipsModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatIconModule,
        MatInputModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatProgressSpinnerModule,
        MatRippleModule,
        MatSelectModule,
        MatSortModule,
        MatSnackBarModule,
        MatTableModule,
        MatTabsModule,
        MatDialogModule,
        MatDatepickerModule,
        MatRadioModule,

        ConfirmarExclusaoDialogComponent,
        AddNewButtonComponent,
        SelectSearchComponent,
        MatSpinnerOverlayComponent,
        EmailComponent,
        TelefoneComponent,
        EnderecoComponent,
        DocumentoDialogComponent,
        DocumentoResponsavelTecnicoDialogComponent,
        DocumentoComponent,
        DocumentoResponsavelTecnicoComponent,
        AtividadeComponent,
        ResponsaveisComponent,
        PessoaFisicaProfissaoComponent,
        MatSelectInfiniteScrollModule,
        NgxMatSelectSearchModule,
        CpfCnpjMaskPipe,
        DialogEditarVinculoPfPjComponent,
        SelectAtividadesArrecadacaoComponent,
        SelectObjetivosLicenciamentoAmbientalComponent,
        NumbersOnlyDirective,
        PesquisarPipe
    ],
    entryComponents: [
        ConfirmarExclusaoDialogComponent,
        AddNewButtonComponent,
        SelectSearchComponent,
        MatSpinnerOverlayComponent,
        DocumentoDialogComponent,
        DocumentoResponsavelTecnicoDialogComponent,
        CustomSnackBarComponent,
        DialogEditarVinculoPfPjComponent,
        DialogSelecionarPfComponent,
        DialogSelecionarEmpresaComponent
    ]
})
export class SharedModule {
}
