export enum EnumUf {
    AC = 'Acre',
    AL = 'Alagoas',
    AM = 'Amazonas',
    AP = 'Amapá',
    BA = 'Bahia',
    CE = 'Ceará',
    DF = 'Distrito Federal',
    ES = 'Espirito Santo',
    GO = 'Goiás',
    MA = 'Maranhão',
    MG = 'Minas Gerais',
    MS = 'Mato Grosso do Sul',
    MT = 'Mato Grosso',
    PA = 'Pará',
    PB = 'Paraíba',
    PE = 'Pernambuco',
    PI = 'Piauí',
    PR = 'Paraná',
    RJ = 'Rio de Janeiro',
    RN = 'Rio Grande do Norte',
    RO = 'Rondõnia',
    RR = 'Roraima',
    RS = 'Rio Grande do Sul',
    SC = 'Santa Catarina',
    SE = 'Sergipe',
    SP = 'São Paulo',
    TO = 'Tocantins'
}

export const ENUM_UF_VALUES: any[] = Object.keys(EnumUf);
