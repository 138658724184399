import {NgModule} from '@angular/core';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule} from '@ngx-translate/core';
import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';
import {FuseProgressBarModule, FuseSidebarModule} from '@fuse/components';
import {fuseConfig} from 'app/fuse-config';
import {AppComponent} from 'app/app.component';
import {LayoutModule} from 'app/layout/layout.module';
import {CommonModule} from '@angular/common';
import {PagesModule} from './main/pages/pages.module';
import {CoreModule} from './main/core/core.module';
import {PageErrorsModule} from 'app/main/errors/page-errors.module';
import {RoutingStateService} from './main/core/services/routing-state.service';
import {AccountService} from './main/core/services/account.service';
import {Principal} from './main/core/services/principal.service';
import {CoreComponentsModule} from 'app/main/core/components/core-components.module';
import {MomentDateAdapter, MOMENT_DATE_FORMATS} from './moment-date-adapter';
import {MAT_DATE_LOCALE, MatPaginatorIntl, MAT_DATE_FORMATS, DateAdapter, MatInputModule} from '@angular/material';
import {getPtBrPaginatorIntl} from './main/shared/utils/ptBr-paginator-intl';
import {AuthenticationInterceptor} from './interceptors/authentication.interceptor';
import 'hammerjs';
import {environment} from 'environments/environment';
import {MatTooltipModule} from "@angular/material/tooltip";
import {LoadingModule} from "./main/shared/components/loading/loading.module";
import {LoadingInterceptor} from "./main/shared/components/loading/loading.interceptor";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";

const appRoutes: Routes = [
    {
        path: '',
        loadChildren: './main/pages/pages.module#PagesModule'
    }
];

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        RouterModule.forRoot(appRoutes, {useHash: true, enableTracing: false}),

        TranslateModule.forRoot(),

        // Material
        MatButtonModule,
        MatIconModule,
        MatInputModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,

        // App modules
        LayoutModule,
        PageErrorsModule,
        CoreModule.forRoot(),
        CoreComponentsModule,
        PagesModule,
        MatTooltipModule,
        LoadingModule
    ],
    exports: [PagesModule, CoreComponentsModule],
    providers: [
        {provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true},
        {provide: MatPaginatorIntl, useValue: getPtBrPaginatorIntl()},
        {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
        {provide: MAT_DATE_FORMATS, useValue: MOMENT_DATE_FORMATS},
        {provide: DateAdapter, useClass: MomentDateAdapter},
        {provide: HTTP_INTERCEPTORS, useClass: AuthenticationInterceptor, multi: true},
        { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline' }},
        RoutingStateService,
        AccountService,
        Principal
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

    constructor() {
        console.log('[licenciamento-configuracao-web] ambiente de ' + environment.name);
    }
}
