import {
    ChangeDetectorRef,
    Component,
    Injector,
    ViewEncapsulation
} from '@angular/core';

import { HttpParams } from '@angular/common/http';
import { fuseAnimations } from '@fuse/animations';
import { BaseListComponent } from 'app/main/shared/components/base-list/base-list.component';
import { ServidorService } from '../servidor.service';
import { Servidor } from '../servidor.model';

const displayedColumns: string[] = [
    'id', 
    'matricula', 
    'cpf',
    'nome',
    'cargo',
    'ativo'
];

@Component({
    selector: 'app-servidor-list',
    templateUrl: './servidor-list.component.html',
    animations: fuseAnimations,
    encapsulation: ViewEncapsulation.None
})
export class ServidorListComponent extends BaseListComponent<Servidor> {
    descricao: string = '';

    constructor(
        injector: Injector,
        service: ServidorService,
        private changeDetectorRefs: ChangeDetectorRef) {
        super(injector, displayedColumns, service);
    }

    fillFilters(httpParams: HttpParams): any {
        if (httpParams == null) {
            httpParams = new HttpParams();
        }

        httpParams = httpParams.append('descricao', this.descricao);        

        return httpParams;
    }
}