import { BaseModel } from 'app/main/shared/models/base.model';
import { Pessoa } from 'app/main/pages/pessoas/pessoa.model';

export class PessoaJuridica extends BaseModel {
    constructor(
        public id?: number,
        public ativo?: boolean,
        public cnpj?: string,
        public nomeFantasia?: string,
        public razaoSocial?: string,
        public inscricaoMunicipal?: string,
        public inscricaoEstadual?: string,
        public naturezaJuridica?: string,
        public porteEmpresa?: string,
        public dataConstituicao?: Date,
        public dataCriacao?: Date,
        public pessoa?: Pessoa,
        public pessoalFisicaCpf?: string,
        public pessoaId?: string,
    ) {
        super();
    }

    static fromJson(json: any): PessoaJuridica {
        if (json === undefined || json === null) {
            return null;
        }
        return new PessoaJuridica(
            json.id, 
            json.ativo,
            json.cnpj,
            json.nomeFantasia,
            json.razaoSocial,
            json.inscricaoMunicipal,
            json.inscricaoEstadual,
            json.naturezaJuridica,
            json.porteEmpresa,
            json.dataConstituicao,
            json.dataCriacao,
            Pessoa.fromJson(json.pessoa),
            json.pessoalFisicaCpf,
            json.pessoaId
        );
    }

    _comboText(): string{
        return `<b>Razão social:</b> ${this.razaoSocial}, <b>CNPJ</b> ${this.cnpj
            .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5')}`;
    }
}
