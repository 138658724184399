import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { UtilService } from './services/util.service';
import { LanguageHelper } from './services/language-helper.service';
import { CoreComponentsModule } from './components/core-components.module';
import { ApplicationStateService } from '../shared/services/application-state.service';
 
@NgModule({
    imports: [
        HttpClientModule,
        TranslateModule,
        FuseSharedModule,
        CoreComponentsModule
    ],
    exports: [FuseSharedModule, CoreComponentsModule],
    providers: [        
        UtilService,
        LanguageHelper,
        ApplicationStateService
    ],
    declarations: []
})
export class CoreModule {
    static forRoot(): ModuleWithProviders {
        return {
            ngModule: CoreModule
        };
    }
}
