import { BaseModel } from 'app/main/shared/models/base.model';
import { PessoaFisica } from '../../pessoas-fisicas/pessoa-fisica.model';
import { TipoOrganizacaoAdministrativa } from '../../tipos-organizacao-administrativa/tipo-organizacao-administrativa.model';

export class OrganizacaoAdministrativa extends BaseModel {
    constructor(
        public id?: number,
        public nome?: string,
        public sigla?: string,
        public organizacaoAdministrativaPai?: OrganizacaoAdministrativa,
        public responsavel?: PessoaFisica,
        public responsavelSubstituto?: PessoaFisica,
        public tipoOrganizacaoAdministrativa?: TipoOrganizacaoAdministrativa
    ) {
        super();
    }
    static fromJson(json: any): OrganizacaoAdministrativa {
        if (json === undefined || json === null) {
            return null;
        }
        return new OrganizacaoAdministrativa(
            json.id,
            json.nome,
            json.sigla,
            OrganizacaoAdministrativa.fromJson(json.organizacaoAdministrativaPai),
            PessoaFisica.fromJson(json.responsavel),
            PessoaFisica.fromJson(json.responsavelSubstituto),
            TipoOrganizacaoAdministrativa.fromJson(json.tipoOrganizacaoAdministrativa)
        );
    }
}
