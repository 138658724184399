import { ObjetivoLicenciamentoAmbiental } from './../../pages/objetivo-licenciamento-ambiental/objetivo-licenciamento-ambiental.model';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pesquisar'
})
export class PesquisarPipe implements PipeTransform {

  transform(itens: any[], pesquisarTxt: string): any[] {
   if(!itens || !itens.length) return itens;
    if(!pesquisarTxt || !pesquisarTxt.length) return itens;
    return itens.filter(item => {
      return item.descricao.toString().toLowerCase().indexOf(pesquisarTxt.toLowerCase()) > -1
    });
  }
  }

