import {environment} from 'environments/environment';


export class Constants {
    public static URL_GATEWAY = environment.URL_GATEWAY;
    public static URL_WSO2_USER_INFO = '';
    public static URL_WSO2_AUTH = '';

    // TOKENS CLIENT
    public static OAUTH_CLIENT_KEY = 'M4xK_8Jjhf0XVdB7cpBiP0JoZ00a';
    public static OAUTH_CLIENT_SECRET = 'aqEtaGeG0Yc5gh4SW0g2_OeEQYYa';
    public static OAUTH_SCOPES = 'email openid profile address photourl picture';

}


