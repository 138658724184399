import {ChangeDetectionStrategy, Component, Injector, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {MatTabChangeEvent} from '@angular/material';
import {fuseAnimations} from '@fuse/animations';
import {AuthenticationService} from 'app/main/core/services/authentication.service';
import {BaseDetailComponent} from 'app/main/shared/components/base-detail/base-detail.component';
import {ConfirmarExclusaoDialogComponent} from 'app/main/shared/components/confirmar-exclusao-dialog/confirmar-exclusao-dialog.component';
import {Cargo} from './../../cargos/cargo.model';
import {CargoService} from './../../cargos/cargo.service';
import {CoreMessageService} from 'app/main/core/services/core-message.service';
import {OrganizacaoAdministrativaService} from '../../gestoes/organizacoes-administrativas/organizacao-administrativa.service';
import {OrganizacaoAdministrativa} from '../../gestoes/organizacoes-administrativas/organizacao-administrativa.model';
import {PessoaFisicaService} from '../../pessoas-fisicas/pessoa-fisica.service';
import {Servidor, ServidorVinculo} from '../servidor.model';
import {ServidorService} from '../servidor.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
    selector: 'app-servidor-detail',
    templateUrl: './servidor-detail.component.html',
    styleUrls: ['./servidor-detail.component.scss'],
    animations: fuseAnimations,
    changeDetection: ChangeDetectionStrategy.Default,
    encapsulation: ViewEncapsulation.None
})
export class ServidorDetailComponent extends BaseDetailComponent<Servidor> implements OnInit {
    cargos: Array<Cargo>;
    organizacoesAdministrativas: Array<OrganizacaoAdministrativa>;
    vinculos: MatTableDataSource<ServidorVinculo> = new MatTableDataSource<ServidorVinculo>();
    vinculosColumns: string[] = ['id', 'organizacaoNome', 'cargoDescricao', 'dataInicio', 'dataFim', 'acoes'];
    compareCargos = (a: any, b: any) => a && b && (a === b || a.descricao === b || b.descricao === a || a.id === b || b.id === a);
    compareOrganizacoesAdministrativas = (a: any, b: any) => a && b && (a === b || a.nome === b || b.nome === a || a.id === b || b.id === a);

    constructor(
        protected injector: Injector,
        protected servidorService: ServidorService,
        protected cargoService: CargoService,
        protected pessoaFisicaService: PessoaFisicaService,
        protected organizacaoAdministrativaService: OrganizacaoAdministrativaService,
    ) {
        super(
            injector,
            new Servidor(),
            servidorService,
            Servidor.fromJson
        );
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.loadCargos();
        this.loadOrganizacoesAdministrativas();
        if (this.currentAction === 'edit') {
            this.pesquisar();
        }
    }

    get cargoId(): number {
        return this.form.get('cargoId').value;
    }

    get cpf(): string {
        const value: string = this.form.get('cpf').value;
        return value.match(/\d+/g).join('');
    }

    get id(): number {
        return this.resource && this.resource.id ? this.resource.id : 0;
    }

    get informacoesServidorDisabled(): boolean {
        return this.isSomenteVisualizacao || (this.isSomenteCriacao && this.id !== 0) || (this.isSomenteEdicao && this.id === 0);
    }

    get isSomenteCriacao(): boolean {
        return this.currentAction === 'new';
    }

    get isSomenteEdicao(): boolean {
        return this.currentAction === 'edit';
    }

    get matricula(): string {
        return this.form.get('matricula').value;
    }

    get pessoaFisicaId(): number {
        return this.form.get('pessoaFisicaId').value;
    }

    get salvarDisabled(): boolean {
        return this.form.invalid || this.form.pristine || this.isSomenteVisualizacao || (this.isSomenteCriacao && this.id !== 0) || (this.isSomenteEdicao && this.id === 0);
    }

    get vinculoDisabled(): boolean {
        return this.isSomenteVisualizacao || (this.vinculoCargoId < 1 || this.vinculoOrganizacaoAdministrativaId < 1);
    }

    get vinculoCargoId(): number {
        return this.form.get('vinculoCargoId').value;
    }

    get vinculoOrganizacaoAdministrativaId(): number {
        return this.form.get('vinculoOrganizacaoAdministrativaId').value;
    }

    get vinculosTabDisabled(): boolean {
        return this.id < 1;
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            cpf: [null, [Validators.required, Validators.pattern(/^[0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}$/)]],
            rg: [null],
            matricula: [null],
            nome: [null],
            dataNascimento: [null],
            profissoes: [null],
            emails: [null],
            telefones: [null],
            cargoId: [null, [Validators.required]],
            pessoaFisicaId: [null],
            vinculoCargoId: [null],
            vinculoOrganizacaoAdministrativaId: [null]
        });
    }

    excluirVinculoDialog(vinculo: ServidorVinculo): void {
        const dialogRef = this.dialog.open(ConfirmarExclusaoDialogComponent, {
            width: '300px',
            data: {
                label: vinculo.id
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            if (!!result) {
                this.excluirVinculo(vinculo);
            }
        });
    }

    private excluirVinculo(vinculo: ServidorVinculo): void {
        const onSuccess = () => {
            this.snackBarService.showSuccess('Vínculo excluído com sucesso.');

            this.loadVinculos();
        };

        this.servidorService
            .deleteVinculo(vinculo)
            .subscribe(
                _ =>  onSuccess(),
                error => this.snackBarService.showError(error)
            );
    }

    private loadCargos(): void {
        this.cargoService
            .getAll()
            .subscribe(cargos => this.cargos = cargos);
    }

    private loadOrganizacoesAdministrativas(): void {
        this.organizacaoAdministrativaService
            .getAll()
            .subscribe(organizacoesAdministrativas => this.organizacoesAdministrativas = organizacoesAdministrativas);
    }

    private loadVinculos(): void {
        if (this.id) {
            this.servidorService
                .getVinculos(this.resource)
                .subscribe(vinculos => this.vinculos.data = vinculos);
        }
    }

    pesquisar(): void {
        const patchValue = (response: any) => {
            this.resource = Servidor.fromJson(response);
            this.form.patchValue(this.resource);
        };
        if (this.currentAction === 'new' && this.cpf.length) {
            this.servidorService
                .getByCpf(this.cpf)
                .subscribe(response => {
                    patchValue(response);
                    this.actionsForSuccess(this.resource);
                }, e => {
                    if (e.status === 400) {
                        this.getPessoaFisica(this.cpf).subscribe(
                            response => {
                                patchValue(response);
                            }, e => this.snackBarService.showError(e.error.errors[0] === '' ? e.error.message : e.error.errors[0]));
                    }
                });
        } else {
            this.servidorService
                .getByCpf(this.cpf)
                .subscribe(response => this.form.patchValue(response));
        }
    }

    private getPessoaFisica(cpf: string): Observable<any> {
        return this.pessoaFisicaService.getByCpf(cpf).pipe(
            map(res => {
                return res = res;
            })
        );
    }

    vincularServidor(): void {
        if (this.id && this.vinculoCargoId && this.vinculoOrganizacaoAdministrativaId) {
            const vinculo: ServidorVinculo = {
                cargoId: this.vinculoCargoId,
                organizacaoId: this.vinculoOrganizacaoAdministrativaId
            };

            this.servidorService
                .createVinculo(this.resource, vinculo)
                .subscribe(
                    _ => this.loadVinculos(),
                    error => this.snackBarService.showError(error)
                );
        }
    }

    onCpfKeyDown(event: KeyboardEvent): void {
        if (event.keyCode === 13) {
            event.preventDefault();

            this.pesquisar();
        }
    }

    onSelectedTabChange(event: MatTabChangeEvent): void {
        if (event.index === 1) { // "Vínculos"
            this.loadVinculos();
        }
    }

    protected creationPageTitle(): string {
        return 'Cadastro de novo Servidor';
    }

    protected editionPageTitle(): string {
        return `Editando o Servidor: ${this.resource.nome}`;
    }

    protected visualizationPageTitle(): string {
        return `Visualizando o Servidor: ${this.resource.nome}`;
    }
}
