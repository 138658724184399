import {NgModule} from '@angular/core';
import { MatTooltipModule } from '@angular/material';
import {RouterModule, Routes} from '@angular/router';
import {CoreComponentsModule} from 'app/main/core/components/core-components.module';
import {SharedModule} from 'app/main/shared/shared.module';
import {CoreModule} from './../../core/core.module';
import {ServidorDetailComponent} from './servidor-detail/servidor-detail.component';
import {ServidorListComponent} from './servidor-list/servidor-list.component';
import {ServidorListResolver, ServidorResolver} from './servidor.service';

export const routes: Routes = [
    {
        path: 'new',
        component: ServidorDetailComponent
    },
    {
        path: 'servidores',
        component: ServidorListComponent,
        resolve: {
            data: ServidorListResolver
        }
    },
    {
        path: ':id/edit',
        component: ServidorDetailComponent,
        resolve: {
            data: ServidorResolver
        }
    },
    {
        path: ':id/detail',
        component: ServidorDetailComponent,
        resolve: {
            data: ServidorResolver
        }
    }
];

@NgModule({
    declarations: [
        ServidorListComponent,
        ServidorDetailComponent
    ],
    imports: [
        CoreModule.forRoot(),
        RouterModule.forChild(routes),
        CoreComponentsModule,
        SharedModule,
        MatTooltipModule
    ],
    entryComponents: [
        ServidorDetailComponent,
        ServidorListComponent
    ],
    providers: [
        ServidorResolver,
        ServidorListResolver
    ]
})
export class ServidoresModule { }
