import {Injectable, Injector} from '@angular/core';
import {OrganizacaoAdministrativa} from './organizacao-administrativa.model';
import {RoutingStateService} from 'app/main/core/services/routing-state.service';
import {BaseService} from 'app/main/shared/services/base.service';
import {ListResolver} from 'app/main/shared/resolvers/list-resolver';
import {DetailResolver} from 'app/main/shared/resolvers/detail-resolver';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ActivatedRouteSnapshot } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable()
export class OrganizacaoAdmnistrativaResolver {
    constructor(
        private service: OrganizacaoAdministrativaService,
        private routingStateService: RoutingStateService
    ) {}

    async resolve(route: ActivatedRouteSnapshot): Promise<OrganizacaoAdministrativa[]> {
        this.routingStateService.addQueryParamsHistory(route.queryParams);
        if (route.params['id'] != null) {
            const organizacoes = await this.service
                .getAllByGestao(route.params['id'])
                .toPromise();
            return organizacoes;
        }
    }

}

@Injectable({
    providedIn: 'root'
})
export class OrganizacaoAdministrativaService extends BaseService<OrganizacaoAdministrativa> {

    constructor(protected injector: Injector) {
        super(
            '/api/organizacoes-administrativas',
            injector,
            OrganizacaoAdministrativa.prototype,
            OrganizacaoAdministrativa.fromJson
        );
    }

    urlResourceScorp: string = environment.URL_SCORP_API

    getAllByGestao(gestaoId): Observable<OrganizacaoAdministrativa[]> {
        const url = `${this.urlResource}/gestao/${gestaoId}`;

        return this.http
            .get(url)
            .pipe(
                map(response => this.jsonToResources(response)),
                catchError(this.handleError.bind(this))
            );
    }

    deleteWithChildrens(id: number, childrensIds: string[]): Observable<any> {
        const url = `${this.urlResource}/${id}/children`;
        return this.http.put(url, childrensIds).pipe(
            map(() => null),
            catchError(this.handleError.bind(this))
        );
    }

    getOrganizacoes(): Observable<OrganizacaoAdministrativa[]> {
        const url = `${this.urlResourceScorp}/api/organizacoes-administrativas`;
        return this.http
            .get(url)
            .pipe(
                map(this.jsonToResources.bind(this)),
                catchError(this.handleError.bind(this))
            );
    }

}
