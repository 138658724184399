import {ChangeDetectionStrategy, Component, Injector, OnInit} from '@angular/core';
import {Conselho} from '../../../shared/models/conselho.model';
import {PessoaFisica} from '../../pessoas-fisicas/pessoa-fisica.model';
import {PessoaJuridica} from '../../pessoa-juridica/pessoa-juridica.model';
import {ResponsavelTecnico} from '../responsavel-tecnico.model';
import {ResponsavelTecnicoService} from '../responsavel-tecnico.service';
import {ConselhoService} from '../../../shared/services/conselho.service';
import {PessoaService} from '../../pessoas/pessoa.service';
import {PessoaFisicaService} from '../../pessoas-fisicas/pessoa-fisica.service';
import {PessoaJuridicaService} from '../../pessoa-juridica/pessoa-juridica.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthenticationService} from 'app/main/core/services/authentication.service';
import {SnackBarService} from '../../../shared/services/snack-bar/snack-bar.service';
import {ActivatedRoute, Router} from '@angular/router';
import {BaseModel} from '../../../shared/models/base.model';
import {Usuario} from '../../../core/models/usuario.model';

@Component({
    selector: 'app-responsavel-tecnico-detail',
    templateUrl: './responsavel-tecnico-detail.component.html'
})
export class ResponsavelTecnicoDetailComponent implements OnInit {

    isSomenteVisualizacao: boolean;
    isCreateMode: boolean;
    tiposPessoa = ResponsavelTecnico.tiposPessoa;

    conselhoList: Conselho[];
    pessoasJuridicaList: PessoaJuridica[];

    form: FormGroup;

    pessoaJuridica: PessoaJuridica = null;
    pessoaFisica: PessoaFisica;
    rt: ResponsavelTecnico;
    user: Usuario;
    comparador = (a, b) => a && b ? a.id === b.id : a === b;
    selectedValue: number;
    constructor(
        protected responsavelTecnicoService: ResponsavelTecnicoService,
        protected injector: Injector,
        protected conselhoService: ConselhoService,
        protected pessoaService: PessoaService,
        protected pessoaFisicaService: PessoaFisicaService,
        protected pessoaJuridicaService: PessoaJuridicaService,
        protected authenticationService: AuthenticationService,
        private formBuilder: FormBuilder,
        private snackBarService: SnackBarService,
        private activatedRoute: ActivatedRoute,
        private router: Router
    ) {

    }

    async ngOnInit(): Promise<void> {
        this.buildResourceForm();
        this.listarConselhos();
        this.listarPessoasJuridicas();
        this.isCreateMode = this.activatedRoute.snapshot.url[0].path === 'new';
        if (!this.isCreateMode) {
            this.isSomenteVisualizacao = this.activatedRoute.snapshot.url[1].path === 'detail';
            const idRt = this.activatedRoute.snapshot.url[0].path;
            this.rt = await this.responsavelTecnicoService.getById(Number(idRt)).toPromise();
            this.selectedValue = this.rt.conselhoId;
            this.form.patchValue({
                tipoPessoa: this.rt.tipoPessoa,
                conselhoId: this.rt.conselhoId,
                registroConselho: this.rt.registroConselho,
                cadastroTecFederal: this.rt.cadastroTecFederal,
                pessoaId: this.rt.pessoaId,
            });
            if (this.rt.tipoPessoa === 'PF') {
                this.pessoaFisicaService.getByPessoaId(this.rt.pessoa.id).subscribe(result => {
                    this.pessoaFisica = result;
                });
            }
        }

        this.user = await this.authenticationService.getUser().toPromise();
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [],
            cadastroTecFederal: [null, [
                Validators.required,
                Validators.minLength(3),
                Validators.maxLength(200)
            ]
            ],
            registroConselho: [
                null,
                [
                    Validators.required,
                    Validators.minLength(3),
                    Validators.maxLength(200)
                ]
            ],
            tipoPessoa: [null, [Validators.required]],
            pessoaId: [null],
            conselhoId: [null, [Validators.required]],
            dataInicio: [null],
            dataFim: [null]
        });
    }

    createUpdate(): void {
        this.form.markAllAsTouched();
        if (this.form.get('pessoaId').value == null && this.form.get('tipoPessoa').value === 'PJ') {
            this.snackBarService.showAlert('Selecione um CNPJ');
        } else {
            this.responsavelTecnicoService.create(this.form.value).subscribe(result => {
                    this.rt = result;
                    this.isCreateMode = false;
                    this.snackBarService.showSuccess('Solicitação processada com sucesso');
                },
                error => this.snackBarService.showError('Erro ao processar a solicitação', error));
        }
    }

    private listarConselhos(): void {
        this.conselhoService
            .getAll()
            .subscribe((conselhos) => {this.conselhoList = conselhos});
    }

    private listarPessoasJuridicas(): void {
        this.pessoaJuridicaService
            .listValidadaComUsuario()
            .subscribe(pjs => (this.pessoasJuridicaList = pjs));
    }

    get dadosPessoaFisica(): PessoaFisica[] {
        return this.pessoaFisica ? [this.pessoaFisica] : [];
    }

    public async cleanForms(): Promise<void> {
        this.form.get('conselhoId').reset();
        this.form.get('registroConselho').reset();
        this.form.get('cadastroTecFederal').reset();
        this.form.get('pessoaId').reset();

        if (this.form.get('tipoPessoa').value === 'PF') {
            if (this.rt == null) {
                this.rt = await this.responsavelTecnicoService.getByCpfCnpj(this.user.login).toPromise().catch(error => null);
            }
            if (this.rt == null) {
                await this.pessoaFisicaService.getByCpf(this.user.login).toPromise().then(pf => this.pessoaFisica = pf,
                    e => this.snackBarService.showError('Erro ao consultar o usuário', e));
                this.form.patchValue({
                    pessoaId: this.pessoaFisica.pessoa.id
                });
            } else {
                this.pessoaFisica = this.rt.pessoa;
                this.form.patchValue({
                    conselhoId: this.rt.conselhoId,
                    registroConselho: this.rt.registroConselho,
                    cadastroTecFederal: this.rt.cadastroTecFederal,
                    pessoaId: this.rt.pessoa.id
                });
            }
            this.form.updateValueAndValidity();
        }
    }

    onChangePJ(): void {
        const pessoaId = this.form.get('pessoaId').value;

        if (pessoaId != null && this.form.get('tipoPessoa').value === 'PJ') {

            this.responsavelTecnicoService.getByCpfCnpj(this.pessoasJuridicaList
                .find(item => item.pessoaId === pessoaId).cnpj).subscribe(result => {
                this.form.patchValue({
                    conselhoId: result.conselhoId,
                    registroConselho: result.registroConselho,
                    cadastroTecFederal: result.cadastroTecFederal
                });
            }, () => {
                this.form.get('conselhoId').reset();
                this.form.get('registroConselho').reset();
                this.form.get('cadastroTecFederal').reset();
            });
        }
    }

    voltar(): void {
        this.router
            .navigateByUrl('responsaveis-tecnicos', {skipLocationChange: true})
            .then(() => this.router.navigate(['responsaveis-tecnicos'], {skipLocationChange: true}));
    }
}
