import {NgModule} from '@angular/core';
import {PessoaFisicaListComponent} from './pessoa-fisica-list/pessoa-fisica-list.component';
import {PessoaFisicaDetailComponent} from './pessoa-fisica-detail/pessoa-fisica-detail.component';
import {RouterModule, Routes} from '@angular/router';
import {PessoaFisicaListResolver, PessoaFisicaResolver} from './pessoa-fisica.service';
import {SharedModule} from 'app/main/shared/shared.module';
import {CoreModule} from 'app/main/core/core.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";

export const routes: Routes = [
    {
        path: 'new',
        component: PessoaFisicaDetailComponent
    },
    {
        path: 'pessoas-fisicas',
        component: PessoaFisicaListComponent,
        resolve: {
            data: PessoaFisicaListResolver
        }
    },
    {
        path: ':id/edit',
        component: PessoaFisicaDetailComponent,
        resolve: {
            data: PessoaFisicaResolver
        }
    },
    {
        path: ':id/detail',
        component: PessoaFisicaDetailComponent,
        resolve: {
            data: PessoaFisicaResolver
        }
    }
];

@NgModule({
    declarations: [PessoaFisicaListComponent, PessoaFisicaDetailComponent],
    imports: [CoreModule, SharedModule, RouterModule.forChild(routes), MatTooltipModule],
    entryComponents: [PessoaFisicaDetailComponent, PessoaFisicaListComponent],
    providers: [PessoaFisicaResolver, PessoaFisicaListResolver]
})
export class PessoasFisicasModule {
}
