import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {OrganizacaoAdministrativa} from '../organizacao-administrativa.model';
import {TipoOrganizacaoAdministrativa} from 'app/main/pages/tipos-organizacao-administrativa/tipo-organizacao-administrativa.model';
import {TipoOrganizacaoAdministrativaService} from 'app/main/pages/tipos-organizacao-administrativa/tipo-organizacao-administrativa.service';
import {OrganizacaoAdministrativaService} from '../organizacao-administrativa.service';
import {PessoaFisicaService} from 'app/main/pages/pessoas-fisicas/pessoa-fisica.service';
import {PessoaFisica} from 'app/main/pages/pessoas-fisicas/pessoa-fisica.model';
import {Gestao} from '../../gestao.model';
import {GestaoOrganizacaoAdministrativaService} from '../../gestao-organizacao-administrativa/gestao-organizacao-administrativa.service';
import {OrganizacaoAdministrativaGrupoCamundaService} from '../../organizacoes-administrativas-grupos-camunda/organizacao-administrativa-grupo-camunda.service';
import {GrupoCamundaService} from 'app/main/shared/services/grupos-camunda.service';
import {GrupoCamunda} from 'app/main/shared/models/grupo-camunda.model';
import {SnackBarService} from 'app/main/shared/services/snack-bar/snack-bar.service';
import {MatDialog} from '@angular/material/dialog';
import {DialogSelecionarPfComponent} from '../../../../shared/components/dialog-selecionar-pf/dialog-selecionar-pf.component';
import {MaskPipe} from 'ngx-mask';

@Component({
    selector: 'app-organizacao-administrativa-detail',
    templateUrl: './organizacao-administrativa-detail.component.html',
    styleUrls: ['./organizacao-administrativa-detail.component.scss']
})
export class OrganizacaoAdministrativaDetailComponent implements OnInit, OnChanges {

    @Input() organizacaoAdministrativa: OrganizacaoAdministrativa;
    @Input() gestao: Gestao;
    @Output() public onChange: EventEmitter<string> = new EventEmitter<string>();

    form: FormGroup;
    tiposOrganizacaoAdministrativa: TipoOrganizacaoAdministrativa[] = [];
    gruposCamunda: GrupoCamunda[] = [];
    isCreating = false;
    organizacaoPai: OrganizacaoAdministrativa = null;
    comparador = (a: TipoOrganizacaoAdministrativa, b: TipoOrganizacaoAdministrativa) =>
        (a && b ? a.id === b.id : a === b)

    constructor(
        private formBuilder: FormBuilder,
        protected snackBarService: SnackBarService,
        private tipoOrganizacaoAdministrativaService: TipoOrganizacaoAdministrativaService,
        private organizacaoAdministrativaService: OrganizacaoAdministrativaService,
        private pessoaFisicaService: PessoaFisicaService,
        private gestaoOrganizacaoAdministrativaService: GestaoOrganizacaoAdministrativaService,
        private organizacaoAdministrativaGrupoCamundaService: OrganizacaoAdministrativaGrupoCamundaService,
        private grupoCamundaService: GrupoCamundaService,
        private dialog: MatDialog,
        private maskPipe: MaskPipe
    ) {
        this.buildResourceForm();
    }

    ngOnInit(): void {
        this.tipoOrganizacaoAdministrativaService.getAll().subscribe(tiposOrganizacoes => {
            this.tiposOrganizacaoAdministrativa = tiposOrganizacoes;
        });

        this.grupoCamundaService.getAllGrupos().subscribe(gruposCamunda => {
            this.gruposCamunda = gruposCamunda;
            // Pesquisa por grupos do camunda na primera vez
            if (this.organizacaoAdministrativa && this.organizacaoAdministrativa.nome) {
                this.getAllGruposCamunda(this.organizacaoAdministrativa.id);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.organizacaoAdministrativa &&
            changes.organizacaoAdministrativa.currentValue) {
            this.organizacaoAdministrativa = changes.organizacaoAdministrativa.currentValue;
            // Valida se existe o atributo nome para entrar em modo edição. Caso seja adicionar, virá apenas com o id preenchido.
            if (this.organizacaoAdministrativa.nome) {
                this.form.patchValue(Object.assign({}, this.organizacaoAdministrativa));
            } else {
                this.isCreating = true;
                this.form.reset();
            }
            if (this.organizacaoAdministrativa.organizacaoAdministrativaPai) {
                this.organizacaoPai = this.organizacaoAdministrativa.organizacaoAdministrativaPai;
                this.form.controls.organizacaoAdministrativaPai.patchValue(
                    this.organizacaoAdministrativa.organizacaoAdministrativaPai.nome
                );
            } else {
                this.form.controls.organizacaoAdministrativaPai.patchValue(null);
            }
            // Pesquisa por grupos do camunda
            if (this.isCreating === false) {
                this.getAllGruposCamunda(this.organizacaoAdministrativa.id);
            }
        }
    }

    protected buildResourceForm(): void {
        this.form = this.formBuilder.group({
            id: [null],
            nome: [null, [Validators.required, Validators.maxLength(200)]],
            sigla: [null],
            tipoOrganizacaoAdministrativa: [null, Validators.required],
            responsavel: [null],
            responsavelSubstituto: [null],
            organizacaoAdministrativaPai: [null],
            gruposCamunda: [null]
        });
        this.form.controls.organizacaoAdministrativaPai.disable();
    }

    private getAllGruposCamunda(organizacaoAdministrativaId: number): void {
        this.organizacaoAdministrativaGrupoCamundaService
            .getAllByOrganizacaoAdministrativa(organizacaoAdministrativaId)
            .subscribe(grupos => {
                if (grupos && grupos.length > 0) {
                    const newGroups: string[] = [];
                    grupos.forEach(el => {
                        const newGroup = this.gruposCamunda.find(g => g.id.toString() === el.grupoCamunda);
                        if (newGroup) {
                            newGroups.push(newGroup.id.toString());
                        }
                    });
                    this.form.controls.gruposCamunda.patchValue(newGroups);
                } else {
                    this.form.controls.gruposCamunda.patchValue(null);
                }
            });
    }

    limpar(): void {
        this.form.patchValue({
            id: null,
            nome: null,
            sigla: null,
            tipoOrganizacaoAdministrativa: null,
            responsavel: null,
            responsavelSubstituto: null
        });
    }

    saveOrUpdate(): void {
        if (this.isCreating) {
            this.salvar();
        } else {
            this.atualizar();
        }
    }

    salvar(): void {
        const oaParentId = (this.organizacaoAdministrativa && this.organizacaoAdministrativa.id) ? this.organizacaoAdministrativa.id : null;
        const organizacaoAdministrativaDTO = {
            nome: this.form.controls.nome.value,
            tipoOrganizacaoAdministrativaId: this.form.controls.tipoOrganizacaoAdministrativa.value.id,
            gestaoId: this.gestao.id,
            gruposCamunda: this.form.controls.gruposCamunda.value ? this.form.controls.gruposCamunda.value.map(obj => ({ grupoCamunda: obj })) : null
        };
        if (oaParentId) {
            organizacaoAdministrativaDTO['organizacaoAdministrativaPai'] = { id: oaParentId };
        }
        if (this.form.controls.sigla.value) {
            organizacaoAdministrativaDTO['sigla'] = this.form.controls.sigla.value;
        }
        if (this.form.controls.responsavel.value) {
            organizacaoAdministrativaDTO['responsavel'] = { id: this.form.controls.responsavel.value.id };
        }
        if (this.form.controls.responsavelSubstituto.value) {
            organizacaoAdministrativaDTO['responsavelSubstituto'] = { id: this.form.controls.responsavelSubstituto.value.id };
        }
        this.organizacaoAdministrativaService
            .create(organizacaoAdministrativaDTO)
            .subscribe(organizacaoSaved =>
                {
                    this.snackBarService.showSuccess(
                        'Organização administrativa adicionada com sucesso!');
                    this.onChange.emit('salvar');
                },
                error => {
                    this.snackBarService.showError('Erro ao salvar Organização administrativa', error);
                }
            );
    }

    atualizar(): void {
        const organizacaoAdministrativaDTO = {
            id: this.organizacaoAdministrativa.id,
            nome: this.form.controls.nome.value,
            sigla: this.form.controls.sigla.value,
            tipoOrganizacaoAdministrativaId: this.form.controls.tipoOrganizacaoAdministrativa.value.id,
            gruposCamunda: this.form.controls.gruposCamunda.value ? this.form.controls.gruposCamunda.value.map(obj => ({ grupoCamunda: obj })) : null
        };
        if (this.organizacaoPai && this.organizacaoPai.id) {
            organizacaoAdministrativaDTO['organizacaoAdministrativaPai'] = { id: this.organizacaoPai.id };
        }
        if (this.form.controls.responsavelSubstituto.value) {
            organizacaoAdministrativaDTO['responsavelSubstituto'] = { id: this.form.controls.responsavelSubstituto.value.id };
        }
        if (this.form.controls.responsavel.value) {
            organizacaoAdministrativaDTO['responsavel'] = { id: this.form.controls.responsavel.value.id };
        }
        this.organizacaoAdministrativaService
            .update(organizacaoAdministrativaDTO)
            .subscribe(organizacaoUpdated =>
                {
                    this.snackBarService.showSuccess(
                        'Gestão administratia atualizada com sucesso!'

                    );
                    this.onChange.emit('salvar');
                },
                error => {
                    this.snackBarService.showError('Erro ao salvar Organização administrativa', error);
                }
            );
    }

    editarResponsavel(responsavel: AbstractControl): void {
        const dialogRef = this.dialog.open(DialogSelecionarPfComponent);
        dialogRef.afterClosed().subscribe(result => {
            if (result){
                responsavel.setValue(result);
            }
        });
    }

    formatPF(pf: PessoaFisica): string {
        return pf ? `${pf.nome} - ${this.maskPipe.transform(pf.cpf, '000.000.000-00')}` : '';
    }
}
