import {BaseModel} from '../../shared/models/base.model';
import {BaseEnum} from '../../shared/models/base.enum ';
import {PessoaFisica} from '../pessoas-fisicas/pessoa-fisica.model';
import {PessoaLicenciamento} from '../../shared/models/pessoa-licenciamento.model';

export class Responsavel extends BaseModel {
    constructor(
        public id?: number,
        public cpf?: string,
        public nome?: string,
        public tipoRepresentante?: string,
        public representadoId?: number,
        public representanteId?: number,
        public ativo?: boolean,
        public representado?: PessoaLicenciamento,
        public representante?: PessoaFisica,
    ) {
        super();
    }


    static tiposRepresentante: BaseEnum[] = [
        {
            valor: 'REPRESENTANTE_LEGAL',
            descricao: 'Representante legal'
        },
        {
            valor: 'RESPONSAVEL_LEGAL',
            descricao: 'Responsavel legal'
        },
        {
            valor: 'SOCIO_COTISTA',
            descricao: 'Sócio cotista'
        }

    ];

    static fromJson(json: any): Responsavel {
        if (json === undefined || json === null) {
            return null;
        }
        return new Responsavel(
            json.id,
            json.cpf,
            json.nome,
            json.tipoRepresentante,
            json.representadoId,
            json.representanteId,
            json.ativo,
            json.representado,
            PessoaFisica.fromJson(json.representante)
        );
    }

    static criarRepresentate(tipoRepresentante, representadoId, representanteId): Responsavel {
        if (tipoRepresentante === undefined || tipoRepresentante === null) {
            return null;
        }
        return new Responsavel(null, null, null,
            tipoRepresentante,
            representadoId,
            representanteId
        );
    }

}
